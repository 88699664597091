import axios from "@/utils/request";
export const publicNewsInfoApi = (data: any) => {
  return axios({
    path: "/publicNewsInfo/selectPage",
    method: "POST",
    data
  })
}

export const publicNewsByNewsTypeId = (data: any) => {
  return axios({
    path: "/publicNewsInfo/getByNewsTypeId",
    method: "POST",
    data
  })
}

export const publicNewsListApi = (data: any) => {
  return axios({
    path: "/publicNewsInfo/list",
    method: "POST",
    data
  })
}

export const publicNewsTypeApi = () => {
  return axios({
    path: "/publicNewsType/selectAll",
    method: "POST",
  })
}

