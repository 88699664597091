import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, ref, onMounted, inject } from "vue";
import { productTypeApi } from "@/api/product";
import { publicNewsTypeApi } from "@/api/news";
import $ from "jquery";
export default defineComponent({
    props: {
        navActiveIndex: {
            type: String,
            default: "1",
        },
    },
    setup: function () {
        var _this = this;
        var coltd = inject("coltd");
        var openeds = ["1", "2", "3", "4", "5"];
        var navbarToggle = function () {
            $("#navbarToggleExternalContent").removeClass("show");
        };
        //根据类型获取产品列表
        var productTypeList = ref("");
        //根据类型获取新闻列表
        var newsTypeList = ref("");
        //获取产品类型列表
        var getProductTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productTypeList;
                        return [4 /*yield*/, productTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var getnewsTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = newsTypeList;
                        return [4 /*yield*/, publicNewsTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            getProductTypeList();
            getnewsTypeList();
        });
        return {
            navbarToggle: navbarToggle,
            productTypeList: productTypeList,
            newsTypeList: newsTypeList,
            openeds: openeds,
            coltd: coltd,
        };
    },
});
